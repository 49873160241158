<template>
  <section>
    <template v-if="!machine.id && !inserting">
      <EmptyListAlert
        buttonLabel="add_new_machine"
        importLabel=""
        :hideImport="true"
        :isInstance="false"
        @new="onClickNew"
        @import="()=>{}"
        newItemPath=""
        :disabled="isModelBased"
      />
    </template>
    <template v-else>
    <form 
      v-on:submit.prevent
      id="form_machine"
      class="form"
      role="form"
      ref="form"
    >
      <div class="row">
        <div class="form-group col-md-6">
          <FormParentSelection
            v-if="parent && parent.id != ''"
            v-model="parent"
            :locker="connector_id ? true : false"
            :service="parentService"
            :value="parent || null"
            :disabled="connector_id ? true : false"
            label="connector"
          />
        </div>
      </div>
      <div class="row">
        <!-- BEGIN MACHINE name -->
        <div class="form-group col-md-4" :class="{ 'has-error': !machine.name }">
          <label for="machine_name" class="no-select">
            {{ $t("titles.machine_name") }}
            <Tooltip :title="$t('hints.machine_name')" />
          </label>
          <div :class="{ 'input-group': machine.id }">
            <InputString
              id="machine_name"
              v-model="machine.name"
              class="form-control text-left"
              :maxSize="255"
              @keydown.prevent.tab.exact="tab"
              @keydown.prevent.shift.tab="shiftTab"
            />
            <span
              v-if="machine?.id"
              class="input-group-addon btn btn-default"
              @click="onClickOnlineeMachineDashboard"
              :title="$t('hints.open_machine_dashboard')"
            >
              <i class="fa fa-dashboard"></i>
              OnlinEE
            </span>
          </div>
        </div>
        <!-- END MACHINE name -->

        <!-- BEGIN MACHINE default_hourly_production -->
        <div class="form-group col-md-2" :class="{ 'has-error': !machine.default_hourly_production }">
          <label for="machine_default_hourly_production" class="no-select">
            {{ $t("titles.machine_default_hourly_production") }}
            <Tooltip :title="$t('hints.machine_default_hourly_production')" />
          </label>
          <InputNumber
            id="machine_default_hourly_production"
            v-model="machine.default_hourly_production"
            class="form-control text-left"
            customPattern="\d+"
            @keydown.prevent.tab.exact="tab"
            @keydown.prevent.shift.tab="shiftTab"
          />
        </div>
        <!-- END MACHINE default_hourly_production -->

        <!-- BEGIN MACHINE unit -->
        <div class="form-group col-md-1" :class="{ 'has-error': !machine.unit }">
          <label for="machine_unit" class="no-select">
            {{ $t("titles.machine_unit") }}
            <Tooltip :title="$t('hints.machine_unit')" />
          </label>
          <InputString
            id="machine_unit"
            v-model="machine.unit"
            class="form-control text-left"
            :maxSize="50"
            @keydown.prevent.tab.exact="tab"
            @keydown.prevent.shift.tab="shiftTab"
          />
        </div>
        <!-- END MACHINE unit -->

        <!-- BEGIN MACHINE production_order_exists -->
        <div class="form-group col-md-4">
          <br class="hidden-xs" />
          <Checkbox
            id="machine_production_order_exists"
            v-model="machine.production_order_exists"
            labelClass="no-select"
            @change="onChangeProductionOrderExists"
            :title="$t('hints.machine_production_order_exists')"
          >
            {{ $t("titles.machine_production_order_exists") }}
            <Tooltip :title="$t('hints.machine_production_order_exists')" />
          </Checkbox>
        </div>
        <!-- END MACHINE production_order_exists -->
      </div>
      <div class="row">
        
        <!-- BEGIN MACHINE limits -->
        <div class="form-group col-md-6">
          <h4>
            <i class="fa fa-sliders"></i>
            {{ $t("limits") }} (%)
            <Tooltip :title="$t('hints.machine_limits')" />
          </h4>
          <div class="form-group row framed" >
            <template v-for="field_name in limit_fields">
              <div :key="field_name" class="col-md-3">
                <!-- BEGIN MACHINE limit -->
                <div class="form-group" :class="{ 'has-error': !machine[field_name] }">
                  <label :for="'machine_'+field_name" class="no-select">
                    {{ $t("titles.machine_"+field_name) }}
                    <Tooltip :title="$t('hints.machine_'+field_name)" />
                  </label>
                  <InputNumber
                    :id="'machine_'+field_name"
                    v-model="machine[field_name]"
                    class="form-control text-left"
                    customPattern="[0-9]+([\.][0-9]+)?"
                    @keydown.prevent.tab.exact="tab"
                    @keydown.prevent.shift.tab="shiftTab"
                  />
                </div>
                <!-- END MACHINE limit -->
              </div>
            </template>
          </div>
        </div>
        <!-- END MACHINE limits -->

        <!-- BEGIN MACHINE url_button -->
        <div class="form-group col-md-6">
          <h4>
            <i class="fa fa-external-link"></i>
            {{ $t("titles.machine_title_url_button") }}
            <Tooltip :title="$t('hints.machine_url_button')" />
          </h4>
          <div class="form-group framed">
            <div class="row">
              <!-- BEGIN MACHINE url -->
              <div class="form-group col-md-12">
                <label for="machine_url" class="no-select">
                  {{ $t("titles.machine_url") }}
                  <Tooltip :title="$t('hints.machine_url')" />
                </label>
                <div class="input-group">
                  <InputString
                    id="machine_url"
                    v-model="machine.url"
                    class="form-control text-left"
                    :maxSize="255"
                    @keydown.prevent.tab.exact="tab"
                    @keydown.prevent.shift.tab="shiftTab"
                  />
                  <span 
                    class="input-group-addon btn btn-default"
                    @click="onClickUseDashboardURL"
                    :title="$t('hints.use_dashboard_url')"
                  >
                    <i class="fa fa-clipboard"></i>
                    {{ $t("titles.use_dashboard_url") }}
                  </span>
                </div>
              </div>
              <!-- END MACHINE url -->
            </div>
            <div class="row">
              <!-- BEGIN MACHINE url_button_text -->
              <div class="form-group col-md-12">
                <label for="machine_url_button_text" class="no-select">
                  {{ $t("titles.machine_url_button_text") }}
                  <Tooltip :title="$t('hints.machine_url_button_text')" />
                </label>
                <InputString
                  id="machine_url"
                  v-model="machine.url_button_text"
                  class="form-control text-left"
                  :maxSize="50"
                  @keydown.prevent.tab.exact="tab"
                  @keydown.prevent.shift.tab="shiftTab"
                />
              </div>
              <!-- END MACHINE url_button_text -->
            </div>
          </div>
        </div>
        <!-- END MACHINE url_button -->
        
      </div>

      <div v-if="ready">
        <FormFooterToolbar
          v-bind:remove="machine.id && !isModelBased ? true : false"
          v-bind:busy="busy"
          v-bind:valid="isValid"
          v-bind:clone="false"
          v-bind:value="cloneEnabled"
          v-bind:showDropdown="false"
          v-model="cloneEnabled"
          v-on:buttonCancelClick="onButtonCancelClick"
          v-on:buttonSaveClick="onButtonSaveClick"
          v-on:buttonRemoveClick="onButtonRemoveClick"
          rule="MaquinaEscrita"
        />
      </div>
    </form>
    </template>
  </section>
</template>

<script>
import FormBase from "@/components/registration/form-base.vue";
import FormParentSelection from "@/components/registration/form-parent-selection.vue";
import Tooltip from "@/components/tooltip.vue";
import EmptyListAlert from "@/components/registration/empty-list-alert.vue";
import InputString from "@/components/input-string.vue";
import InputNumber from "@/components/input-number.vue";
import Checkbox from "@/components/base/checkbox.vue";
import MachineService from "@/services/machine.js";
import { ExtFunctions } from "@/assets/ext-functions.js";

function defaultData() {
  return {
    machine: {
      id: "",
      etag: "",
      name: "",
      connector_id: 0,
      default_hourly_production: "",
      unit: "PÇS",
      casualty_limit: 50.00,
      unavailability_limit: 50.00,
      low_quality_limit: 50.00,
      low_performance_limit: 50.00,
      production_order_filtering: false,
      production_order_exists: false,
      url: "",
      url_button_text: ""
    },
    ready: false,
    inserting: false,
    limit_fields: [
      'casualty_limit',
      'unavailability_limit',
      'low_quality_limit',
      'low_performance_limit'
    ]
  };
}

export default {
  name: "FormMachine",
  extends: FormBase,
  components: {
    FormParentSelection,
    EmptyListAlert,
    Tooltip,
    Checkbox,
    InputString,
    InputNumber
  },
  props: {
    connector_id: {
      type: Number,
      required: true
    },
    machine_id: {
      required: true
    },
    reload: {
      type: Number,
      required: true
    }
  },
  data() {
    return defaultData();
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"];
    },
    connectorId() {
      return parseInt(
        this.connector_id || this.$route.params.connector_id || 0
      );
    },
    connector() {
      return this.$store.getters["dashboard/connectorList"].find(
          ({ id }) => parseInt(id) == this.connectorId
        );
    },
    parent() {
      return {
        id: this.connectorId ?? "",
        name: this.connector?.name ?? ""
      }
    },
    isValid() {
      return this.machine.name &&
        this.machine.default_hourly_production &&
        this.machine.unit &&
        this.machine.casualty_limit &&
        this.machine.unavailability_limit &&
        this.machine.low_quality_limit &&
        this.machine.low_performance_limit
        ? true
        : false;
    },
    payload() {
      let payload = { ...defaultData().machine, ...this.machine };

      payload.contract_id = this.contract.id;
      payload.connector_id = this.connectorId;

      if (this.machine.id) {
        payload.id = this.machine.id;
      }

      return payload;
    },
    removalMessage() {
      let msg = "";
      let item = this.machine;
      msg = this.warningContent(
        "machine",
        item.name,
        "you_wont_be_able_to_revert_this"
      );
      return msg;
    }
  },
  watch: {
    busy(n) {
      this.$emit("loading", n);
    },
    reload(n) {
      this.setup();
    },
    machine_id: {
      handler(n, o) {
        this.setup();
      },
      immediate: true
    }
  },
  methods: {
    resetData() {
      let data = defaultData();
      Object.keys(data).forEach((k) => {
        if (Object.prototype.hasOwnProperty.call(this.$data, k)) {
          this.$data[k] = data[k];
        }
      });
    },
    save() {
      let payload = this.payload;
      this.busy = true;
      let srv = new MachineService();
      srv.save(payload).then((ret) => {
        this.busy = false;
        if (this.validateSaveResponse(ret)) {
          this.machine.id = ret.id;
          this.machine.etag = ret.etag;
          this.$store.dispatch("onlinee/setMachine", this.machine);
          this.inserting = false;
          this.exitSuccess = true;
          this.showAlert();
          
          if (!this.machine_id) {
            this.$nextTick(() => {
              this.$emit("setMachineId", this.machine.id);
            });
          }
        } else {
          this.showAlert();
        }
      });
    },
    remove() {
      this.doRemove(this.payload, new MachineService());
    },
    doRemove: function(payload, srv) {
      this.busy = true;
      srv.remove(payload).then((ret) => {
        this.busy = false;
        if (this.validateDeleteResponse(ret)) {
          this.inserting = false;
          this.exitSuccess = true;
          this.$store.dispatch("onlinee/removeMachine", this.machine);
          this.showAlert();
          this.$nextTick(() => {
            this.resetData();
            this.$emit("setMachineId", null);
          });
        } else {
          this.showAlert();
        }
      });
    },
    setup() {
      this.ready = false;

      if (this.machine_id) {
        this.machine = (this.$store.getters["onlinee/machines"] || []).find(
          (item) => item.id = this.machine_id
        );
        this.ready = true;
      } else {
        this.resetData();
        return;
      }

      if (this.$utils.gup("a") == "d") {
        this.$nextTick(() => {
          this.onButtonRemoveClick();
        });
      }
    },
    onClickNew() {
      this.ready = true;
      this.inserting = true;
    },
    onClickUseDashboardURL() {
      this.machine.url = "/dashboard/equipment/" + this.connectorId;
      this.machine.url_button_text = this.machine.url_button_text || this.$t("default_url_button_text");
    },
    onClickOnlineeMachineDashboard() {
      ExtFunctions['onlineeMachineDashboard'](this, this?.machine?.id);
    },
    onChangeProductionOrderExists() {
      this.machine.production_order_filtering = this.machine.production_order_exists;
    },
    tab() {
      let $e = document.activeElement;
      let inputSet =
        ($e && ($e.closest("#form_machine") )) || null;
      if (inputSet) {
        let allInputs = Array.from(inputSet.querySelectorAll("input:not([disabled])"));
        let nextIndex = allInputs.indexOf($e);
        if (nextIndex >= allInputs.length - 1) {
          nextIndex = 0;
        } else {
          nextIndex++;
        }
        allInputs[nextIndex].focus();
        allInputs[nextIndex].select();
      }
    },
    shiftTab() {
      let $e = document.activeElement;
      let inputSet =
        ($e && ($e.closest("#form_machine") )) || null;
      if (inputSet) {
        let allInputs = Array.from(inputSet.querySelectorAll("input:not([disabled])"));
        let nextIndex = allInputs.indexOf($e);
        if (nextIndex == 0) {
          nextIndex = allInputs.length - 1;
        } else {
          nextIndex--;
        }
        allInputs[nextIndex].focus();
        allInputs[nextIndex].select();
      }
    }
  },
  created() {
    this.parentService = this.newConnectorService(!this.connector_id);
    this.rule = "MaquinaCadastro";
    this.setup();
  }
};
</script>

<style>
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.framed {
  background-color: whitesmoke;
  border: 1px solid lightgray;
  padding: 20px 10px;
  margin: 0 0 20px 0;
    margin-top: 0px;
  border-radius: 5px;
}

.skin-dark .framed {
  background-color: var(--skin-dark-dark);
  border-color: var(--skin-dark-darker);
}

.framed > .col-md-3 {
  padding-left: 0;
}
</style>
