<template>
  <section v-if="ready && (!connectorModelId || items)">
    <PanelHeaderEquipmentList
      v-if="header"
      :title="title"
      :nav="nav"
      :connector_id="connectorId"
      icon="fa fa-dashboard"
    />
    <div
      class="tab-pane active"
      role="tabpanel"
      aria-labelledby=""
      v-if="items && isEmpty && showEmptyPanel && !busy && deletedItemsLoaded"
    >
      <EmptyListAlert
        buttonLabel="add_new_connector"
        importLabel="import_connectors"
        @import="importConnectors()"
        :newItemPath="newItemPath"
      />
    </div>
    <template v-else-if="items">
      <div class="tab-pane active" id="edit" role="tabpanel" aria-labelledby="">
        <FileExplorer
          ref="fileExplorer"
          :items="items"
          :modal="false"
          :multiSelection="multiSelection"
          :title="`${maxResult} ${$tc('connector', maxResult > 1 ? 2 : 1)}`"
          :maxResult="maxResult"
          :discarded="discarded"
          @change="tree = $event"
          @open="onOpen"
          @close="onClose"
          @drop="resetSearch"
          @beforeRestore="resetSearch"
          @sidebarResize="onSidebarResize"
          @move="onItemsMoved"
          dbKey="tree_connector"
          :sidebarEnabled="!$utils.isMobile() && !connectorModelId"
        >
          <template #search>
            <div style="padding: 15px 10px 5px 10px; position: relative">
              <SearchableTableQueryInput
                ref="query"
                v-if="tree"
                v-model="query"
                :createCommand="$refs.stbl.createCommand"
                @create="create"
              >
                <template
                  #customFilters
                  v-if="
                    contract &&
                      contract.allowed_model_connectors &&
                      !connectorModelId
                  "
                >
                  <div class="row">
                    <div class="form-group col-md-6 custom-filters">
                      <label
                        class="filter-option noselect"
                        v-for="item in inlineFilter"
                        :key="item.name"
                      >
                        <input type="checkbox" v-model="item.value" />
                        <span>
                          {{ $t(item.title) }}
                          <Spin
                            v-if="
                              item.name == 'only_deleted' &&
                                fetchingDeletedItems
                            "
                            :inline="true"
                            divClasses=""
                            sizeClass=""
                          ></Spin>
                        </span>
                      </label>
                    </div>
                  </div>
                </template>
                <template #extraButtons>
                  <button
                    id="export"
                    type="button"
                    class="btn btn-default"
                    :disabled="
                      (filteredItems && filteredItems.length == 0) ||
                        filteredOnlyDeleted
                    "
                    @click.stop.prevent="downloadCSV()"
                    :title="$t('hints.button_export')"
                  >
                    <i class="fa fa-file-excel-o" />
                  </button>
                  <button
                    id="downloadBackup"
                    type="button"
                    class="btn btn-default"
                    :disabled="
                      (filteredItems && filteredItems.length == 0) ||
                        multiSelection.values.length == 0 ||
                        filteredOnlyDeleted
                    "
                    @click.stop.prevent="downloadBackup()"
                    :title="$t('hints.button_download_backup')"
                  >
                    <i class="fa fa-download" />
                  </button>
                  <!-- TODO: since multi item restore is not possible yet, keep it invisible -->
                  <button
                    style="display: none"
                    v-if="multiSelection.key"
                    id="restore"
                    class="btn btn-default"
                    :disabled="
                      !nSelected || nSelected > 1 || !filteredOnlyDeleted
                    "
                    @click="massRestore"
                    :title="$t('restore')"
                  >
                    <i class="fa fa-undo" />
                  </button>
                  <button
                    v-if="multiSelection.key"
                    id="mass_remove"
                    type="button"
                    class="btn btn-default"
                    :disabled="!nSelected || filteredOnlyDeleted"
                    @click="massRemove"
                    :title="$t('mass_remove')"
                  >
                    <i class="fa fa-trash-o" />
                  </button>
                </template>
                <template #statistics>
                  <ResourceStatistics
                    resource="connector"
                    :total="nTotal"
                    :showing="nShowing"
                    icon="fa fa-plug"
                  />
                </template>
              </SearchableTableQueryInput>
              <div v-if="msgBoard" class="alert alert-default msg-board">
                {{ msgBoard }}
              </div>
            </div>
          </template>
          <template #files>
            <SearchableTable
              v-show="tree"
              :class="
                $refs.fileExplorer && $refs.fileExplorer.ready
                  ? 'easy-show'
                  : 'easy-hide'
              "
              class="table-container"
              :show="$refs.fileExplorer && $refs.fileExplorer.ready"
              :items="filteredItems"
              :fields="fields"
              :commands="commands"
              :multiColumnOrder="false"
              :clientSort="true"
              :pagination="pagination"
              :maxResult="maxResult"
              :deepSearch="false"
              :loading="busy"
              :disabled="cloneEnabled"
              :multiSelection="multiSelection"
              :searchEnabled="false"
              :customFilters="inlineFilter"
              @select="onSelect"
              @command="onCommand"
              @multiColumnSort="onMultiColumnSort"
              @nItems="maxResult = $event"
              @filter:restored="filterRestored"
              ref="stbl"
              :style="{
                'margin-top':
                  $refs.stbl && $refs.stbl.showPagination ? '-6px' : '0'
              }"
            >
              <template #paginationBefore>
                <div
                  v-if="!connectorModelId && tree && !tree.show"
                  :class="{'pull-left': pagination, clear: 'both'}"
                >
                  <div
                    class="btn-sidepanel-toggle btn btn-default hidden-xs"
                    @click="$refs.fileExplorer.toggle"
                  >
                    <i class="fa fa-list"></i>
                  </div>
                </div>
              </template>
              <template #id="entry" v-if="tree">
                <div
                  class="jstree-draggable"
                  draggable="true"
                  :data-item-id="entry.item.id"
                  :data-item-name="entry.item.name"
                  @mousedown.stop.prevent="$refs.fileExplorer.dragStart"
                >
                  <i :class="draggableIcon"></i>
                  <span> {{ entry.item.id }}</span>
                </div>
              </template>
              <template v-slot:screen_id="entry">
                <ScreenSelectionCell :item="entry.item" :key="entry.item.id">
                  <template #before>
                    <i class="fa fa-alarm"></i>
                  </template>
                </ScreenSelectionCell>
              </template>
              <template v-slot:name="entry">
                <span>
                  <i
                    :class="info(entry.item).class"
                    :title="info(entry.item).status"
                  ></i>
                  {{ entry.item.name }}
                </span>
              </template>
              <template v-slot:serial_number="entry">
                <template
                  v-if="entry.item.deleted_at && !entry.item.serial_number"
                >
                  <template v-if="!entry.item.base_model">
                    <span class="text-red">
                      <i class="fa fa-times-circle"></i> {{ $t("removed") }}
                    </span>
                  </template>
                  <template v-else> - </template>
                </template>
                <template
                  v-if="!entry.item.deleted_at && !entry.item.serial_number"
                >
                  <template
                    v-if="!is_mqtt(entry.item) && !entry.item.base_model"
                  >
                    <span class="text-red">
                      <i class="fa fa-warning"></i> {{ $t("pending") }}
                    </span>
                  </template>
                  <template v-else> - </template>
                </template>
                <template v-else>
                  {{ entry.item.serial_number }}
                </template>
              </template>
              <template v-slot:toggle="entry">
                <div v-if="entry.item.deleted_at || entry.item.base_model">
                  -
                </div>
                <ResourceToggle :item="entry.item" :parent="me()" v-else />
              </template>
              <template v-slot:info="entry">
                <span
                  :title="info(entry.item).title"
                  style="white-space: nowrap"
                  v-html="info(entry.item).value"
                >
                </span>
              </template>
            </SearchableTable>
          </template>
        </FileExplorer>
      </div>
      <div class="tab-pane" id="list" role="tabpanel" aria-labelledby=""></div>
    </template>
    <Spin v-else></Spin>
    <ModalImportFile
      :entity="importEntity"
      :hasBasic="true"
      accept=".csv,.hpc"
      @close="clearImportEntity"
      @resource_imported="resourceImported"
    />
  </section>
</template>
<script>
import {debounce} from "lodash";
import {isMQTT} from "@/services/connector.js";
import DashboardEditPickerBase from "@/components/registration/dashboard-edit-picker-base.vue";
import SearchableTable from "@/components/searchable-table.vue";
import ResourceStatistics from "@/components/statistics/resource-statistics.vue";
import ConnectorService from "@/services/connector.js";
import EmptyListAlert from "@/components/registration/empty-list-alert.vue";
import ModalImportFile from "@/components/modal-import-file.vue";
import ScreenSelectionCell from "@/components/registration/screen-selection-cell.vue";
import ResourceToggle from "@/components/registration/resource-toggle.vue";
import FileExplorer from "@/components/editor/file-explorer.vue";
import SearchableTableQueryInput from "@/components/searchable-table-query-input.vue";
import ScreenFinder from "@/utils/screen-finder.js";
import Spin from "@/components/spin.vue";

export default {
  name: "DashboardEditConnectorPicker",
  extends: DashboardEditPickerBase,
  components: {
    SearchableTable,
    ResourceStatistics,
    EmptyListAlert,
    ModalImportFile,
    ScreenSelectionCell,
    ResourceToggle,
    SearchableTableQueryInput,
    FileExplorer,
    Spin
  },
  props: {
    showEmptyPanel: {
      type: Boolean,
      required: false,
      default: true
    },
    connectorModelId: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      tree: null,
      entity: "connector",
      title: "connector_list",
      importEntity: "",
      filtering: false,
      deletedItemsLoaded: false,
      fetchingDeletedItems: false,
      iQuery: null,
      inlineFilter: [
        {
          name: "connectors",
          title: "connectors",
          value: false,
          filter(value, item) {
            return value && !item.base_model && !item.base_model_id;
          }
        },
        {
          name: "models",
          title: "models",
          value: false,
          filter(value, item) {
            return value && item.base_model;
          }
        },
        {
          name: "model_based_connectors",
          title: "model_based_connectors",
          value: false,
          filter(value, item) {
            return value && item.base_model_id;
          }
        },
        {
          name: "only_deleted",
          title: "only_deleted",
          value: false,
          filter(value, item) {
            return value && item.deleted_at != null;
          }
        }
      ]
    };
  },
  computed: {
    fields() {
      return [
        {
          name: "id",
          title: "id",
          style() {
            return {"white-space": "nowrap"};
          }
        },
        {
          name: "name",
          title: "connector",
          style() {
            return {overflow: "hidden", "text-overflow": "ellipsis"};
          }
        },
        {
          name: "type",
          title: "type",
          hint: this.$t("type") + "/" + this.$t("protocol"),
          visible: !this.connectorModelId,
          parser(item) {
            return `${item?.connector_type?.name || ""} / ${item?.protocol
              ?.name || ""}`;
          }
        },
        {
          name: "serial_number",
          title: "serial_number",
          parser(item) {
            return item.serial_number || "-";
          },
          style() {
            return {"text-align": "center"};
          }
        },
        {
          name: "location_name",
          title: "location",
          order_id: "location",
          style() {
            // return { "min-width": "100px" };
          },
          parser(item) {
            return item.location_name || "-";
          }
        },
        {
          name: "screen_id",
          title: "screen",
          parser: (item) => {
            const finder = new ScreenFinder(this, item);
            return finder?.screen?.name || "";
          }
        },
        {
          name: "info",
          title: " ",
          sortable: true,
          tooltip: `${this.$t(
            "specific_properties"
          )}:<div class='text-left data-flags'>${this.info()}</div>`,
          style() {
            return {"text-align": "center", "min-width": "40px"};
          },
          parser: (item) => {
            return this.connectorType(item).value;
          }
        },
        {
          name: "toggle",
          title: "enabled",
          visible: () => !this.filteredOnlyDeleted,
          parser: (item) => {
            return `${String.fromCharCode(
              122 + parseInt(item.enabled ? 0 : 1)
            )} ${this.$t(item.enabled ? "yes" : "no")} ${
              item.enabled ? "true" : "false"
            }`;
          },
          style() {
            return {"text-align": "center"};
          }
        },
        {
          name: "deleted_at",
          title: "deleted_at",
          style: {"text-align": "center"},
          hint: (item) => {
            return `${item.id} ${item.name}`;
          },
          visible: () => this.filteredOnlyDeleted,
          format: (value) => {
            return value ? this.$dt.format(value) : "-";
          }
        }
      ];
    },
    commands() {
      let items = [
        {
          name: "create",
          title: "new",
          icon: "fa fa-plus",
          enabled: () => {
            return this.$can("manage", "EquipamentoCadastro");
          },
          commands: []
        },
        {
          name: "edit",
          title: "edit",
          icon: "fa fa-pencil",
          visible: !this.filteredOnlyDeleted,
          enabled: () => {
            return (
              !this.filteredOnlyDeleted &&
              !this.nSelected &&
              this.$can("manage", "EquipamentoCadastro")
            );
          }
        },
        {
          name: "clone",
          title: "clone",
          icon: "fa fa-copy",
          visible: !this.filteredOnlyDeleted,
          enabled: () => {
            return (
              !this.filteredOnlyDeleted &&
              !this.nSelected &&
              this.$can("manage", "EquipamentoCadastro")
            );
          }
        },
        {
          name: "restore",
          title: "restore",
          icon: "fa fa-undo",
          visible: this.filteredOnlyDeleted,
          enabled: (item) => {
            return (
              this.filteredOnlyDeleted &&
              item.id &&
              item.deleted_at &&
              !this.nSelected &&
              this.$can("manage", "EquipamentoCadastro")
            );
          }
        },
        {
          name: "remove",
          title: "remove",
          icon: "fa fa-trash",
          visible: !this.filteredOnlyDeleted,
          enabled: () => {
            return (
              !this.filteredOnlyDeleted &&
              !this.nSelected &&
              this.$can("manage", "EquipamentoCadastro")
            );
          }
        },
        {name: "-"},
        {
          name: "open",
          title: "device_list",
          icon: "portal-icon device[solid]",
          visible: !this.filteredOnlyDeleted,
          enabled: () => {
            return !this.filteredOnlyDeleted && !this.nSelected;
          }
        },
        {
          name: "addChild",
          title: "add_new_device",
          icon: "fa fa-plus",
          visible: !this.filteredOnlyDeleted,
          enabled: (item) => {
            return (
              !this.filteredOnlyDeleted &&
              !this.nSelected &&
              !item.base_model_id &&
              this.$can("manage", "EstacaoCadastro")
            );
          }
        }
      ];
      if ((this.$store.getters["dashboard/connectorList"] || []).length) {
        if (this.$can("manage", "EstacaoCadastro"))
          items[0].commands.push({
            name: "newDevice",
            title: "new_device",
            icon: "fa fa-plus"
          });
        if (this.$can("manage", "DadoCadastro"))
          items[0].commands.push({
            name: "newData",
            title: "new_data",
            icon: "fa fa-plus"
          });
        if (!this.isFreePlan && this.$can("manage", "AlarmeCadastro"))
          items[0].commands.push({
            name: "newAlarm",
            title: "new_alarm",
            icon: "fa fa-plus"
          });
      }
      if (this.$can("manage", "EquipamentoCadastro"))
        items[0].commands.push({
          name: "importConnectors",
          title: "import_connectors",
          icon: "fa fa-plus"
        });
      return items;
    },
    filterOn() {
      return this.inlineFilter.some(({value}) => value);
    },
    folderMode() {
      // return this.$refs.fileExplorer && this.tree && this.tree.show ? true : false;
      return this.tree && this.tree.show ? true : false;
    },
    selectedNode() {
      return (this.folderMode && this.tree.selectedNode) || "";
    },
    activeList() {
      return this?.tree?.show
        ? this.list
        : (this.list || []).filter(({deleted_at}) => !deleted_at);
    },
    filteredOnlyDeleted() {
      return (
        this.inlineFilter &&
        this.inlineFilter.length &&
        this.inlineFilter.some(
          (item) => item.name == "only_deleted" && item.value
        )
      );
    },
    filteredItems() {
      const src =
        this?.tree?.show || this.filteredOnlyDeleted
          ? this.list
          : this.activeList;
      let lst =
        this.filtering && this.$refs.stbl
          ? this.$refs.stbl.search(src || [])
          : src;
      lst =
        this.selectedNode && !this.connectorModelId
          ? lst.filter(({id}) => this.selectedNode === this.tree.leaves[id])
          : lst;
      return this.filterOn
        ? (lst || []).filter((i) => this.filterItem(i))
        : lst;
    },
    nItems() {
      return this?.connectorModelId ? this.list.length : this.maxResult;
    },
    nTotal() {
      if (this.filteredOnlyDeleted) return this.discarded.length;

      return this.folderMode && this.selectedNode && this.filteredOnlyDeleted
        ? this.discarded.length || 0
        : ((this.items && this.items.length) || 0) -
            (this.discarded.length || 0);
    },
    nShowing() {
      const n =
        (this.$refs.stbl &&
          this.$refs.stbl.itemList &&
          this.$refs.stbl.itemList.length) ||
        this.nItems;
      // the selectedNode and maxResult are here just to trigger reactivity
      return (this.maxResult && this.selectedNode && n) || n;
    },
    list() {
      if (this.connectorModelId) {
        return (this.items || []).filter(
          ({base_model_id}) =>
            parseInt(base_model_id) == parseInt(this.connectorModelId)
        );
      }
      return this.items;
    },
    discarded() {
      return (this.items || [])
        .filter(({deleted_at}) => deleted_at)
        .map(({id}) => id);
    },
    recoverablePeriod() {
      return this.$root.config.recoverable_period || 30;
    },
    trashCanSelected() {
      return this?.tree?.show && this?.tree?.selectedNode == "trash_can";
    },
    msgBoard() {
      let msg = [];
      if (this.filteredOnlyDeleted) {
        msg.push(
          this.$t("hints.cleaning_due_date", {days: this.recoverablePeriod})
        );
      }
      return msg.join(" | ");
    },
    newItemPath() {
      let url = `/dashboard/edit/connector/0`;
      if (this?.connectorModelId) {
        url += `?model=${this?.connectorModelId}`;
      }
      return url;
    },
    isEmpty() {
      return !(this?.items || []).length;
    },
    query: {
      set(value) {
        this.iQuery = value;
        if (this.$refs.stbl && this.$refs.stbl.query != value) {
          this.$refs.stbl.query = value;
          this.filtering = this.$utils.trim(this.$refs.stbl.query) !== "";
          this.findNodes();
        }
      },
      get() {
        // iQuery is required due reactivity issues among stbl and query input
        return this.iQuery || this?.$refs?.stbl?.query || "";
      }
    },
    draggableIcon() {
      return this?.tree?.icons?.leaf || "fa fa-file-o";
    },
    ready() {
      return this.$store.getters.isReady;
    }
  },
  methods: {
    filterItem(item) {
      if (!this.filterOn) return true;
      return this.inlineFilter
        .map((option) => !option.value || option.filter(option.value, item))
        .reduce((accumulator, valid) => accumulator && valid);
    },
    filterRestored(filters) {
      if (!filters) return;
      this.iQuery = filters.query;
      let lst = (filters && filters.custom_filters) || null;
      if (!lst || !lst.length || lst.length != this.inlineFilter.length) return;
      let prv = null;
      for (var i = 0; i < this.inlineFilter.length; i++) {
        prv = lst.find(({name}) => name == this.inlineFilter[i].name);
        if (prv) this.inlineFilter[i].value = prv.value;
      }
    },
    removalMessage(item) {
      let msg = "";

      // In mass remove mode
      if (item && Array.isArray(item)) {
        return this.warningContent(
          "connector",
          this.$tc("n_records_selected", item.length, {amount: item.length})
        );
      }

      // Single record remove mode
      if (item?.device?.connector?.base_model) {
        let message = this.$t("removal_model_message");
        let field_name = this.$tc("data");
        let value = item.name;
        let warning = `<p>${message}</p>`;
        let html = `<b>${field_name}</b>: ${value}${warning}`;
        msg = this.wrap(html); // can be implemented at child level
      }
      return msg;
    },
    create(command) {
      if (command == "create") {
        let params =
          this.tree && this.tree.show && this.tree.selectedNode
            ? `?parent=${this.tree?.selectedNode}`
            : "";
        this.$router.push(`${this.newItemPath}${params}`);
      } else {
        this.onCommand({name: command});
      }
    },
    open(command) {
      this.$router.push(
        "/dashboard/edit/connector/" + command.target.id + "/device"
      );
    },
    edit(command) {
      this.$router.push(`/dashboard/edit/connector/${command.target.id}#edit`);
    },
    clone(command) {
      let params =
        this.tree && this.tree.show && this.tree.selectedNode
          ? `?a=c&parent=${this.tree?.selectedNode}`
          : "?a=c";
      this.$router.push(
        `/dashboard/edit/connector/${command.target.id}${params}`
      );
    },
    is_mqtt(item) {
      return isMQTT(item);
    },
    wrap(html) {
      let wrapper = document.createElement("div");
      wrapper.innerHTML = html;
      return wrapper;
    },
    restore(command) {
      if (!command.target) return;
      if (command.target.id > 0) {
        let connector = (this.items || []).find(
          ({id}) => id == command.target.id
        );
        if (connector) {
          let content = "";

          if (!connector.base_model && !isMQTT(connector)) {
            content = this.$t("you_really_want_to_restore");
            content = `${content}<br><span class="text-danger"><i class="fa fa-warning"></i>`;
            content = `${content} ${this.$t(
              "you_will_need_to_fill_the_serial_number"
            )}</span>`;
          } else {
            content = this.$t("you_really_want_to_restore");
          }

          this.$swal({
            title: this.$t("are_you_sure"),
            content: this.wrap(content),
            icon: "warning",
            buttons: [this.$t("cancel"), this.$t("restore")],
            dangerMode: true
          }).then((ok) => {
            if (ok) {
              this.restoreItem(connector);
            }
          });
        }
      }
    },
    restoreItem(connector) {
      let old_deleted_at = connector.deleted_at;
      let old_screen_id = connector.screen_id;
      connector.deleted_at = null;
      connector.screen_id =
        connector.screen_id != 1000000000 ? connector.screen_id : null;
      this.$emit("loading", true);
      this.service.save(connector).then((ret) => {
        this.$emit("loading", false);
        if (ret && ret.id) {
          this.$store.dispatch("dashboard/fetchConnector", {
            id: connector.id,
            deep: true
          });
          this.$utils.notifyUser(
            this,
            this.$t("you_have_restored_n_items", {count: 1})
          );
        } else {
          connector.deleted_at = old_deleted_at;
          connector.screen_id = old_screen_id;
          this.validateSaveResponse(ret);
          this.showAlert();
        }
      });
    },
    resourceImported(response, entity) {
      let msg = this.$t("success_import_message");
      this.validateSaveResponse(response, msg);
      this.showAlert();
      if (response && response instanceof Array) {
        this.$emit("resource_imported", entity);
        this.fetchItems().then(() => {
          if (this.selectedNode && this.tree.show) {
            response.forEach(({id}) => {
              this.$store.dispatch("dashboard/setParent", {
                dbKey: "tree_connector",
                id: id,
                parentId: this.selectedNode
              });
            });
            this.$nextTick(() => {
              if (
                this.$refs?.fileExplorer &&
                this.$refs?.fileExplorer?.items &&
                this.$refs.fileExplorer.items.length &&
                this.tree.show &&
                this.$refs.fileExplorer.show
              ) {
                this.$refs.fileExplorer.show();
              }
            });
          }
        });
      }
    },
    onAfterRemoval(ok) {
      ok &&
        (this.items || [])
          .filter(({deleted_at}) => deleted_at)
          .forEach((i) => (i.serial_number = null));
    },
    remove(command) {
      let config = {
        item: command.target,
        type: "connector",
        resource_key: "connector_id",
        rule: "EquipamentoEscrita",
        service: this.service,
        keep_deleted: true,
        hide_warning: true
      };

      if (
        command.target.base_model &&
        this.modelInstances(command.target).length > 0
      ) {
        this.$swal({
          title: this.$t("item_could_not_be_deleted"),
          text: this.$t("model_has_instances"),
          icon: "error"
        });
        return;
      }
      this.validateAndRemove(config, this.onAfterRemoval);
    },
    massRemove(command) {
      if (!this.multiSelection.values.length) return;
      let config = {
        items: this.multiSelection.values,
        type: "connector",
        resource_key: "connector_id",
        rule: "EquipamentoEscrita",
        service: this.service,
        keep_deleted: true
      };

      let hasInstances = (this.list || []).some((connector) => {
        return (
          this.multiSelection.values.indexOf(connector.id) >= 0 &&
          connector.base_model &&
          this.modelInstances(connector).length > 0
        );
      });

      if (hasInstances) {
        this.$swal({
          title: this.$t("item_could_not_be_deleted"),
          text: this.$t("model_has_instances"),
          icon: "error"
        });
        return;
      }

      this.validateAndMassRemove(config, this.onAfterRemoval);
    },
    massRestore(command) {
      if (this.multiSelection.values.length > 1) {
        this.$swal({
          title: this.$t("action_denied"),
          text: this.$t("you_cant_restore_multiple_connectors"),
          icon: "error"
        });
        return;
      }
      let connector = (this.items || []).find(
        ({id}) => id == this.multiSelection.values[0]
      );
      this.multiSelection.values = [];
      this.restoreItem(connector);
    },
    addChild(command) {
      this.$router.push(
        "/dashboard/edit/connector/" + command.target.id + "/device/0"
      );
    },
    fetchItems() {
      return new Promise((resolve) => {
        if (this?.connectorModelId && this.connectorList.length) {
          this.$set(this, "items", this.connectorList);
          this.maxResult = this.items.length;
          resolve(this.items);
          return;
        }
        let query = {
          contract_id: this.contract.id
        };
        this.fetch(query).then((ret) => {
          if (ret) {
            this.$store.dispatch("dashboard/setConnectorValue", this.items);
          }
          resolve(this.items);
        });
      });
    },
    fetchDeletedItems() {
      this.fetchingDeletedItems = true;
      return new Promise((resolve) => {
        if (this.deletedItemsLoaded) {
          this.fetchingDeletedItems = false;
          resolve(null);
          return;
        }
        let query = {
          contract_id: this.contract.id,
          only_deleted: true
        };
        this.service.fetch(query).then((ret) => {
          this.deletedItemsLoaded = true;
          this.fetchingDeletedItems = false;
          if (ret && ret.length) {
            this.items = (this.items || []).concat(
              ret.filter(({deleted_at}) => deleted_at)
            );
          }
          resolve(ret);
        });
      });
    },
    onOpen() {
      this.$emit("open");
    },
    onClose() {
      if (this.command) {
        this.$emit(this.command.name, this.command.event);
      }
      this.$emit("close");
      this.command = null;
    },
    onItemsMoved($event) {
      if (!$event) return;

      // Restaurando recursos
      if ($event.from == "trash_can" && ($event.items || []).length) {
        if ($event.items.length > 1) {
          this.$swal({
            title: this.$t("action_denied"),
            text: this.$t("you_cant_restore_multiple_connectors"),
            icon: "error"
          });
          this.$nextTick(() => {
            if (this.$refs.fileExplorer)
              this.$refs.fileExplorer.moveLeaves($event.items, $event.from);
          });
          return;
        }
        let connector = (this.items || []).find(
          ({id}) => id == $event.items[0]
        );

        this.restoreItem(connector);
      }
      // Removendo recursos
      else if ($event.to == "trash_can" && ($event.items || []).length) {
        let payload = {
          remove_type: "partial",
          resource_key: "connector_id",
          resource_ids: $event.items,
          contract_id: this?.contract?.id,
          keep_deleted: true
        };

        let hasInstances = (this.list || []).some((connector) => {
          return (
            $event.items.indexOf(connector.id) >= 0 &&
            connector.base_model &&
            this.modelInstances(connector).length > 0
          );
        });

        if (hasInstances) {
          this.$utils.notifyUser(this, this.$t("model_has_instances"), {
            type: "error",
            icon: "warning"
          });
          this.$nextTick(() => {
            if (this.$refs.fileExplorer)
              this.$refs.fileExplorer.moveLeaves($event.items, $event.from);
          });
          return;
        }

        this.removeResources(payload);
      }
    },
    resetSearch() {
      this.clearSelection();
      this.inlineFilter.forEach((i) => (i.value = false));
      this.query = "";
      if (this.$refs.query) this.$refs.query.resetQuery();
    },
    async findNodes() {
      this._search =
        this._search ||
        debounce(() => {
          if (!this.folderMode) return;
          let ids = [];
          const hasQuery =
            this.$utils.trim(this?.$refs?.stbl?.query ?? "") !== "";
          if ((hasQuery || this.filterOn) && this.$refs.stbl) {
            let lst = this.$refs.stbl.search(this.list);
            lst = this.filterOn
              ? (lst || []).filter((i) => this.filterItem(i))
              : lst;
            ids = lst.map(({id}) => id);
          }
          this.$refs.fileExplorer.findNodesByLeafId(ids);
        }, 500);
      this._search();
    },
    connectorType(item) {
      let type = this.$tc(
        item.base_model
          ? "model"
          : item.base_model_id
          ? "instance"
          : "connector",
        1
      );
      return {label: type, value: `${type.charAt(0).toUpperCase()}`};
    },
    info(item) {
      if (item) {
        const type = this.connectorType(item);
        return {
          value: type.value,
          title: type.label,
          status:
            type.label +
            ": " +
            this.$tc(
              item.base_model
                ? "not_available"
                : item.is_connected
                ? "connected"
                : "disconnected",
              1
            ),
          class: item.base_model
            ? "fa fa-chain-broken fa-status fa-disabled"
            : item.is_connected
            ? "fa fa-link fa-status text-success"
            : "fa fa-chain-broken fa-status text-danger"
        };
      } else {
        return [
          {value: "C", title: this.$tc("connector", 1)},
          {value: "M", title: this.$tc("model", 1)},
          {value: "I", title: this.$tc("instance", 1)}
        ]
          .map((i) => `<div>${i.value} = ${i.title}</div>`)
          .join("");
      }
    },
    onSidebarResize() {
      this.$nextTick(() => {
        if (this.$refs.stbl) {
          this.$refs.stbl.syncColumnResizeHandle();
        }
      });
    },
    modelInstances(connector) {
      if (!connector.base_model || connector.deleted_at) return [];
      return (this.list || []).filter(
        ({id, base_model_id, deleted_at}) =>
          !deleted_at &&
          base_model_id == connector.id &&
          this.multiSelection.values.indexOf(id) < 0
      );
    }
  },
  watch: {
    items(n, o) {
      if (n && o && n.length != o.length && this.$refs.fileExplorer) {
        this.$nextTick(() => {
          if (
            this.$refs.fileExplorer &&
            n.length == this.$refs.fileExplorer.items.length
          ) {
            if (this.$refs.fileExplorer.syncLeaves) {
              this.$refs.fileExplorer.syncLeaves();
            }
          }
        });
      }
    },
    filterOn() {
      this.findNodes();
    },
    folderMode(n, o) {
      if (this.$refs.stbl) this.$refs.stbl.save();
      if (n && this.filteredOnlyDeleted) {
        this.$nextTick(() => {
          if (this?.$refs?.fileExplorer)
            this.$refs.fileExplorer.selectNode("trash_can");
        });
      }
    },
    filteredOnlyDeleted(n) {
      let self = this;
      this.fetchDeletedItems().then(() => {
        if (self.$refs && self.$refs.fileExplorer) {
          if (!self.trashCanSelected && n) {
            self.$refs.fileExplorer.selectNode("trash_can");
          } else if (self.trashCanSelected && !n) {
            self.$refs.fileExplorer.selectNode("root");
          }
        }
      });
    },
    trashCanSelected(n) {
      this.inlineFilter.find(({name}) => name == "only_deleted").value = n;
    },
    connectorList: {
      handler(n, o) {
        let result;
        this.busy = false;
        if (n && n.length) {
          try {
            result = structuredClone(n);
          } catch (error) {
            result = JSON.parse(JSON.stringify(n));
          }
          if (result && (!this.items || !this.items.length)) {
            this.onResposeReady(result);
            this.$emit("loading", false);
          }
        }
      },
      immediate: true,
      deep: true
    },
    ready: {
      handler(n) {
        if (n && !this.busy) {
          this.$emit("loading", false);
          this.fetchDeletedItems();
          this.$nextTick(() => {
            if (this.isEmpty) {
              this.onResposeReady([]);
            }
          });
        }
      },
      immediate: true
    }
  },
  beforeCreate() {
    this.service = new ConnectorService();
    this.$emit("rule", "EquipamentoAcesso");
  }
};
</script>

<style scoped>
section > .tab-pane {
  margin: 5px 0 30px 0;
  padding: 10px 0 0 0;
}
div.search-container {
  padding: 15px 10px 5px 10px;
}
.custom-filters {
  min-width: 100%;
  width: 100%;
  margin: 15px 0px -15px;
  white-space: nowrap;
}
.filter-option {
  margin-right: 15px;
}
.filter-option span {
  vertical-align: top;
  margin-left: 5px;
}
.filter-option:hover {
  cursor: pointer;
  color: #72afd2;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.table-container {
  min-height: 100px;
  max-height: 100%;
  height: calc(100%);
  overflow: visible;
}

@media (max-width: 768px) {
  .table-container {
    overflow: auto;
  }
}
.jstree-draggable {
  white-space: nowrap;
  min-width: 40px;
  display: inline-block;
}
.jstree-draggable > i {
  margin-right: 6px;
}

.fa-opaque {
  opacity: 0.5;
}

.fa-status {
  margin: 0 5px;
}

.fa-disabled {
  color: 0;
  opacity: 0.5;
}

.btn-sidepanel-toggle {
  margin: 0 25px 0 10px;
}

.alert-default {
  background-color: whitesmoke;
  color: #555;
  text-shadow: #bbb 0px 1px;
  text-align: center;
}

.skin-dark .alert-default {
  background-color: #222d32;
  color: #b8c7ce;
  text-shadow: inherit;
}

.msg-board {
  position: absolute;
  width: 100%;
  bottom: -50px;
  padding: 5px;
  border-radius: 5px;
  margin-left: -15px;
}

@media (max-width: 768px) {
  .custom-filters {
    white-space: initial;
  }
  #export {
    margin-left: -32px;
  }
}

.skin-dark .info-box {
  background-color: #2c3b41;
  color: #b8c7ce;
  border-color: #222d32;
}
.skin-dark .filter-option.noselect {
  color: #b8c7ce;
}
</style>
